<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="id"   style="display: none" >
        <a-input v-model="form.id" placeholder="请输入" disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="用户ID"   >
        <a-input v-model="form.userNo" placeholder="请输入" disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="昵称"  >
        <a-input v-model="form.nickname" placeholder="请输入昵称" disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="封禁原因"   >
        <a-textarea class="heartreportlist_modal2_0_c5" placeholder="请输入封禁原因" v-model="form.reason" :max-length="240" style="height:80px;" :auto-size="true">
        </a-textarea>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            确定
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { banDeviceNo,banUserIp } from '@/api/biz/userLogin'

export default {
  name: 'BanUserForm',

  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id:null,
        nickname: null,
        userNo: null,
        reason:null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        reason: [
          { required: true, message: '封禁原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id:null,
        nickname: null,
        userNo: null,
        reason:null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, title) {
      this.reset()
      this.formType = 2
      this.form={}
      if(title=='device'){
        this.formTitle = '封禁设备'
      }else{
        this.formTitle = '封禁IP'
      }
      this.open = true
      Object.assign(this.form, row);
    },
    /** 提交按钮 */
    submitForm: function () {
      debugger
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.status=0;
          if (this.form.id !== undefined && this.form.id !== null) {
            console.log(this.form);
            if(this.formTitle=='封禁设备'){
              banDeviceNo(this.form).then(response => {
                this.$message.success(
                  '操作成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }).finally(() => {
                this.submitLoading = false
              })
            }else {
              banUserIp(this.form).then(response => {
                this.$message.success(
                  '操作成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }).finally(() => {
                this.submitLoading = false
              })
            }

          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
