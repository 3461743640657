<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="登录用户ID" prop="userNo">
                <a-input v-model="queryParam.userNo" placeholder="请输入登录用户ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="登录IP" prop="userIp">
                <a-input v-model="queryParam.userIp" placeholder="请输入登录IP" allow-clear/>
              </a-form-item>
            </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="设备号" prop="deviceNo">
                  <a-input v-model="queryParam.deviceNo" placeholder="请输入设备号" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:user:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:user:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:user:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:user:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <ban-user-form ref="banUserForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:user:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['biz:user:remove']" />-->
          <a @click="handleDelete(record)" v-hasPermi="['biz:user:remove']">
            <a-icon type="delete" />删除
          </a>
            <a-divider type="vertical" v-hasPermi="['biz:user:edit']" />
           <a-dropdown v-hasPermi="['system:user:resetPwd', 'system:user:edit']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="double-right" />
            更多
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-hasPermi="['system:user:resetPwd']"  v-if="record.banStatus=='无'&&record.deviceNo!=null&&record.deviceNo!=''" >
              <a @click="$refs.banUserForm.handleUpdate(record, 'device')">
                <a-icon type="stop" />
                封禁设备
              </a>
            </a-menu-item>
            <a-menu-item v-hasPermi="['system:user:edit']" v-if="record.banStatus=='无'&&record.userIp!=null&&record.userIp!=''" >
              <a  @click="$refs.banUserForm.handleUpdate(record, 'ip')">
                <a-icon type="stop" />
                封禁IP
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLoginUser,listUser, delUser } from '@/api/biz/userLogin'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import BanUserForm from '@/views/biz/userLogin/modules/BanUserForm.vue'

export default {
  name: 'User',
  components: {
    BanUserForm,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        userIp: null,
        deviceNo: null,
        deviceType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '登录用户ID',
          dataIndex: 'userNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '登录IP',
          dataIndex: 'userIp',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备号',
          dataIndex: 'deviceNo',
          ellipsis: true,width: 280,
          align: 'center'
        },
        {
          title: '设备端类型',
          dataIndex: 'deviceType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (text === 'iOS') {
              return '苹果'
            } else if (text === 'Android') {
              return '安卓'
            } else if (text === 'Other') {
              return '其他'
            } else  {
              return '其他'
            }
          }
        },
        {
          title: '首次登录',
          dataIndex: 'isFirstLogin',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (text === 1) {
              return '是'
            } else{
              return '否'
            }
          }
        },
        {
          title: '封禁状态',
          dataIndex: 'banStatus',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户登录记录列表 */
    getList () {
      this.loading = true
      pageLoginUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        userIp: undefined,
        deviceNo: undefined,
        deviceType: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 封禁设备按钮操作 */
    handleBanDevice (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认封禁所选中数据?',
        content: '当前选中设备号为 ' + row.deviceNo + ' 的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return banDeviceNo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 封禁IP按钮操作 */
    handleBanUserIp(row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认封禁所选中数据?',
        content: '当前选中记录IP为 ' + row.userIp + ' 的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return banUserIp(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/userLogin/export', {
            ...that.queryParam
          }, `用户登录记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
